# CoffeeScript

require('./side-effects')

{ValueConverter} = require './value-converter'
{ColumnFilter} = require './column-filter'
{StateTracker} = require './state-tracker'
{Validator} = require './validator'
{AjaxSelection} = require './ajax-selection'
{fillUrlPlaceholders} = require './helpers'

{createJqueryPlugin} = require 'src/js/helpers/create-jquery-plugin'

{Editor, Buttons} = $.fn.dataTable
{postJson} = require 'src/coffee/infrastructure/ajax'
{getCurrentLocale, getCurrentLanguage, getDataTablesTranslations} = require 'src/js/i18n'
{parse} = require 'src/js/i18n/conversion'

# datetime picker fixes

class StaticGrid
    default_layout: """
        <'row'<'col-sm-6'l><'col-sm-6'f>>
        <'row'<'col-sm-12'tr>>
        <'row'<'col-sm-5'i><'col-sm-7'p>>
    """

    layout: """
        <'panel panel-default'
        <'panel-heading'<'row'<'col-sm-4 grid-header'><'col-sm-4 builtin-buttons'><'col-sm-4'f>>>
        <tr>
        <'panel-footer'<'row'<'col-sm-6'l><'col-sm-6'i><'clearfix'><'col-sm-7 edit-buttons'><'col-sm-5'p>>>
        >
    """

    layout_minimal: """
        <'panel panel-default'
        <tr>
        <'panel-footer'<'row'<'col-sm-12 edit-buttons'>>>
        >
    """

    constructor: (@$table, @options = {}) ->
        @counter = 0
        @additionalColumnData = {}

        @$wrapper = @$table.closest('.grid-wrapper')

        @converter = new ValueConverter this
        @state = new StateTracker this
        @validator = new Validator this

        @loadControlConfig()

        @createDataTable()

        @$container = $ @table.table().container()

        @adjustDom()
        @addButtons()
        @setupEvents()
        @state.setupEvents()

        @$container.closest '.grid-container'
        .addClass 'in'
        .prev()
        .addClass 'hidden'
        @domFixes()

        @options.promises.addedToDom.then =>
            if @$table.data 'ajax-url'
                @table.ajax.reload null, false

    createDataTable: =>
        @readColumnsFromDom()

        fields = []

        for column in @columns
            continue unless column.visible
            field =
                name: column.name
                label: column.title
                def: column.default

            switch column.type
                when 'DateTime'
                    field.type = 'datetime'
                    field.opt =
                        format: column.datetime.format
                        locale: getCurrentLocale()

                when 'Boolean'
                    if @editControls.edit.mode is 'Inline'
                        field.type = 'select'
                        field.options = [
                            {label: 'Ja', value: true}
                            {label: 'Nein', value: false}
                        ]
                        if column.nullable
                            field.options.push {label: '', value: null}
                    else
                        field.type = 'boolSwitch'
                        field.options =
                            nullable: column.nullable
                        field.def ?= if column.nullable then null else false

            if column.name of @guidSelects
                field.type = 'selectize'
                field.options = @guidSelects[column.name]

            if column.name of @stringSelects
                field.type = 'selectize'
                field.options = @stringSelects[column.name]

            if column.type of @enums
                field.type = 'selectize'
                field.options = @enums[column.type]

            if !!column.loadAction

                loader = (action, columnName) => (query, callback) =>
                    queryData = @additionalColumnData[columnName] or {}
                    queryData.query = query
                    $.get action, queryData
                    .done (result) -> callback result
                field.type = 'selectize'
                field.name = column.name + '.uid'
                field.opts =
                    searchField: 'label'
                    load: loader column.loadAction, column.name
                    closeAfterSelect: true
                    selectOnTab: true
                if column.create
                    field.opts.create = (input) =>
                        value: '00000000-0000-0000-0000-000000000000'
                        label: input
                    field.opts.createOnBlur = true

            fields.push field

        dataTablesTranslations = getDataTablesTranslations()

        editorOptions =
            ajax: @state.ajax
            table: @$table
            fields: fields
            display: 'bootstrap'
            i18n: dataTablesTranslations

        @editor = new Editor editorOptions

        layout = @layout
        if @options.minimal
            layout = @layout_minimal

        dataTableOptions =
            autoWidth: false
            scrollX: true
            language: dataTablesTranslations
#            language:
#                url: languageFile
            dom: layout
            columnDefs: [
                {targets: '_all', render: {display: @converter.renderFunction, sort: @converter.sortFunction}}
            ]
            columns: for column in @columns
                continue unless column.visible

                name: column.name
                data: if not column.loadAction then column.name else "#{column.name}.name"
                editField: if not column.loadAction then column.name else "#{column.name}.uid"
                title: column.title
                type: switch column.type
                    when 'DateTime' then 'date'
                    when 'Float' then 'num'
                    when 'Integer' then 'num'
                    else 'string'
                defaultContent: column.defaultContent
                orderable: if column.orderable is undefined then true else column.orderable
                searchable: if column.searchable is undefined then true else column.searchable
            pagingType: 'numbers'
            paging: @options.paging and not @options.minimal
            keys:
                columns: ':not(:first-child)'
                editor: @editor
                keys: [ "\t".charCodeAt(0) ]
                editOnFocus: true
        @options.ajax = {}

        if @$table.data 'ajax-url'
            @options.ajax.url = @$table.data 'ajax-url'
            if @$table.data 'ajax-row-number-url'
                @options.ajax.rowNumberUrl = @$table.data 'ajax-row-number-url'
            else
                @options.ajax.rowNumberUrl = @options.ajax.url + 'RowNumberForId'
            dataTableOptions.serverSide = true
            dataTableOptions.ajax = @ajaxDataProvider
            dataTableOptions.deferRender = true
            dataTableOptions.processing = true
        else
            ensureRowIdIsSet = (obj, i) =>
                obj.DT_RowId ?= "#{@$table.attr 'id'}_row_#{i}"
                return obj

            dataTableOptions.data = @data = (ensureRowIdIsSet(@converter.processJSObject(obj) ,i) for obj, i in JSON.parse(@$wrapper.find('script.data')[0].innerText) when obj isnt undefined and obj isnt null)

        @options.excel = {}

        if @$table.data 'default-excel-plugin'
            value = @$table.data('default-excel-plugin')
            @options.excel.defaultPlugin = JSON.parse(value.toLowerCase())

        if @$table.data 'excel-ajax-url'
            @options.excel.ajaxUrl = @$table.data 'excel-ajax-url'
            @options.excel.available =  !!@options.excel.ajaxUrl
        if @options.selection
            dataTableOptions.select =
                style: @options.selection
                items: 'row'
            if @editControls.edit.mode in ['Inline', 'Bubble']
                dataTableOptions.columns = [
                    {
                        data: null,
                        defaultContent: '',
                        className: 'select-checkbox',
                        orderable: false
                    }
                ].concat dataTableOptions.columns
                dataTableOptions.order = [[1, 'asc' ]]
                dataTableOptions.select.selector = 'td:first-child'

        @table = @$table.DataTable dataTableOptions

        if @$table.data 'use-column-filtering'
            @table.columns().every ->
                searchable = this.settings()[0].aoColumns.filter((col) => col.data is this.dataSrc())[0].searchable
                return unless searchable
                new ColumnFilter this, dataTableOptions.serverSide # we want 'this' to be our column

        @table.rows().deselect() if @options.selection # to display selection help text

    setSelectizeQueryData: (columnName, obj) =>
        @editor.field(columnName + '.uid').inst().clear(true)
        @editor.field(columnName + '.uid').inst().clearOptions()
        @additionalColumnData[columnName] = obj

    prepareAjaxData: (data) =>
        payload = @options.ajax.payload

        if payload instanceof Function
            data.payload = payload()
        else
            data.payload = payload

        #Escape backslashes in the searchstring since they need to be escaped for the SQL
        data.search.value = data.search.value.replace(/\\/g, '\\\\')

        for column in data.columns
            column.search.value = column.search.value.replace(/\\/g, '\\\\') #Do the same for column based search values
            column.datetimeformat = @columnLookup[column.data].datetime.format

    ajaxDataProvider: (data, callback, settings) =>
        return unless @options.promises.addedToDom.state() is 'resolved'

        @prepareAjaxData data

        postJson @options.ajax.url, data
        .then (response) =>
            for entry, i in response.data
                response.data[i] = @converter.processJSObject entry
            callback response

    selectRow: (rowId) =>
        pageInfo = @table.page.info()
        calcTargetPage =
            (targetIndex) -> (targetIndex - (targetIndex % pageInfo.length)) / pageInfo.length #internal pagenumbers are zero based

        if @options.ajax.url
            ajaxData = @table.ajax.params()
            @prepareAjaxData ajaxData
            ajaxData.RequestedRowId = rowId

            postJson @options.ajax.rowNumberUrl, ajaxData
            .then (targetIndex) =>
                if targetIndex == -1 #row not found
                    return
                targetPage = calcTargetPage(targetIndex)
                if pageInfo.page != targetPage
                    @$table.one('draw.dt', @handlePageChangeDraw(rowId))
                    @table.page(targetPage).draw('page')
                else
                    @table.draw('page').row('#' + rowId).select()
        else
            dataset = @table.rows({search: 'applied'}).data()
            targetIndex = dataset.toArray().findIndex((x) -> x.DT_RowId == rowId || x.uid == rowId)
            if targetIndex == -1 #row not found
                    return
            targetPage = calcTargetPage(targetIndex)
            @table.row('#' + rowId).select()
            @table.page(targetPage).draw('page')

    handlePageChangeDraw: (rowId) =>
        (e) =>
            @table.row('#' + rowId).select()

    ajaxExcelExporter: (e, dt, node, config) =>
        data = @table.ajax.params()
        @prepareAjaxData data
        data.start = 0
        data.length = 2147483647 #int max

        @$container.find '.buttons-excel'
        .find '.fa'
        .removeClass 'fa-download'
        .addClass 'fa-spinner fa-spin'

        postJson @options.excel.ajaxUrl, data
        .then (result) =>
          @$container.find '.buttons-excel'
          .find '.fa'
          .removeClass 'fa-spinner fa-spin'
          .addClass 'fa-download'

          window.location = '/Download/Temp/' + escape(result)
        .fail =>
          @$container.container().find '.buttons-excel'
          .find '.fa'
          .removeClass 'fa-spinner fa-spin'
          .addClass 'fa-download'

    readColumnsFromDom: =>
        @columnOptions = JSON.parse(@$wrapper.find('script.columnoptions')[0].innerText)

        @enums = {}
        @guidSelects = {}
        @stringSelects = {}

        @columnLookup = {}
        @columns = []

        selectToOptions = ($select, container, key) ->
            container[key] = for option in $select.children()
                $option = $ option
                label: $option.text()
                value: $option.data 'value'

        for columnspec in @columnOptions
            column =
                name: columnspec.propertyName
                title: columnspec.localizedTitle
                type: columnspec.typeName
                index: columnspec.order
                visible: not columnspec.invisible
                orderable: if columnspec.orderable is undefined then true else columnspec.orderable
                searchable: if columnspec.searchable is undefined then true else columnspec.searchable
                nullable: !!columnspec.nullable
                required: !!columnspec.required
                readonly: !!columnspec.readonly
                loadAction: columnspec.loadAction
                create: columnspec.create
                numberFormatter:
                    minimumFractionDigits: columnspec.minDecimalDigits
                    maximumFractionDigits: columnspec.maxDecimalDigits
                datetime:
                    format: columnspec.dateTimeFormat
                maxLength: if columnspec.maxLength is undefined then 0 else columnspec.maxLength

            if !!column.loadAction
                column.defaultContent = ''

            if columnspec.isIdColumn
                @idColumn = column.name

            $enum = @$wrapper.find(".enums select[data-enum-name='#{column.type}']")

            if $enum.length
                selectToOptions $enum, @enums, column.type

            if columnspec.guidSelectItems
                @guidSelects[column.name] = columnspec.guidSelectItems
            if columnspec.stringSelectItems
                @stringSelects[column.name] = columnspec.stringSelectItems

            column.default = @converter.processJS columnspec.defaultValue, column
            columnName = if not column.loadAction then column.name else column.name + '.name'
            @columnLookup[columnName] = column
            @columns.push column

        @$table.find('thead').empty()

    loadControlConfig: =>
        $editButtonContainer = @$table.closest('.grid-wrapper').find('.edit-button-group')

        @editControls = {}

        for key, config in ['add', 'edit', 'delete']
            @editControls[key] =
                mode: $editButtonContainer.data "#{key}-mode"
                url: $editButtonContainer.data "#{key}-url"

    domFixes: =>

        # fixes first time edit submit button not working properly
        $(@editor.dom.formError).hide()

        checkForTabs = =>
            $tab = @$container.closest('.tab-pane')
            $tabHeader = $ ".nav.nav-tabs li > a[href=\"##{$tab.attr 'id'}\"]"
            $tabHeader.on 'shown.bs.tab', => #on instead of one to prevent columns failing when data is changed later
                @table.columns.adjust()

        @options.promises.addedToDom.then =>
            #$('.selectize-dropdown.single').hide()
            $('body .selectize-dropdown').hide()

            @table.columns.adjust()
            checkForTabs()

    addButtons: =>
        buttons = [
                #'csvHtml5'
                'selectAll',
                'selectNone',
                {extend: 'create', editor: @editor},
                {extend: 'edit',   editor: @editor},
                {extend: 'remove', editor: @editor},
                {
                    extend: 'print',
                    editor: @editor,
                    exportOptions: {
                        stripHtml: false
                    },
                    title: @$container.find('table').data('title') or "",
                    message: @$container.find('table').data('subtitle') or ""
                },
            ]

        if @options.customButtons
          # make the button container to 100%, that all buttons are in a row
          @$container.parent().find('.edit-buttons').removeClass('col-sm-7').addClass('col-sm-12')
          # add custom buttons to the button array
          for button in @options.customButtons
            buttons.push({
                text: button.name,
                className: "custom-btn",
                action: button.action
              }
            )

        if @options.excel.defaultPlugin
            buttons.push(
                extend: 'excel'
                exportOptions:
                    columns: ':visible'
                    format:
                        body: (data, row, column, node) ->
                            if typeof data isnt "string" or data is null
                                return data
                            parsedData = parse data
                            return if parsedData is null or isNaN parsedData then data else parsedData.toString()
          )

        if @options.excel.available
            excelbutton = {
                text: 'Excel',
                className: 'buttons-excel',
                action: @ajaxExcelExporter
                }
            buttons.push(excelbutton)


        new Buttons @table,
            buttons: buttons

        $builtinButtonContainer = @table.buttons().container()

        $editButtonContainer = @$container.parent().find '.edit-button-group'
        $builtinButtonContainer.appendTo @$container.find '.builtin-buttons'

        # disable builtin buttons if necessary
        if @options.selection not in ['multi', 'os']
            $builtinButtonContainer.find('.buttons-select-all').addClass 'disabled'

        if not @options.selection
            $builtinButtonContainer.find('.buttons-select-none').addClass 'disabled'

        @editControls.add.$button = $builtinButtonContainer.find('.buttons-create')
        @editControls.edit.$button = $builtinButtonContainer.find('.buttons-edit')
        @editControls.delete.$button = $builtinButtonContainer.find('.buttons-remove')

        for key, config of @editControls
            continue unless config.mode is 'None' or (config.mode is 'Url' and not config.url)

            $original = config.$button
            config.$button = $original.clone().disable()
            $original.remove()

        if @editControls.add.mode is 'Url' and @editControls.add.url
            @editControls.add.$button.attr 'href', @editControls.add.url

        $printButton = $builtinButtonContainer.find '.buttons-print'

        $printButton
        .text ' ' + $printButton.text()
        .prepend '<i class="icon icon-ix-print">'

        @editControls.add.$button
        .text ' ' + @editControls.add.$button.text()
        .prepend '<i class="icon icon-ix-add">'
        @editControls.edit.$button
        .text ' ' + @editControls.edit.$button.text()
        .prepend '<i class="icon icon-ix-edit">'
        @editControls.delete.$button
        .text ' ' + @editControls.delete.$button.text()

        $editButtonContainer
        .append @editControls.add.$button
        .append @editControls.edit.$button
        .append @editControls.delete.$button

        if @options.customButtons
          $customGridButtons = $builtinButtonContainer.find '.custom-btn'

          # clone the button lists
          customButtonClones = @options.customButtons.slice(0)
          gridButtons = $customGridButtons.slice(0)

          for button, index in customButtonClones
            gridButton = gridButtons[index]
            $gridButton = $ gridButton
            btnId = """id#{button.id}"""
            $gridButton.attr("id", btnId)
            $gridButton.disable()
            if button.icon
              $gridButton.prepend $ "<i class=\"#{button.icon}\">"
              $iconTag = $gridButton.find 'i'
              $iconTag.css('padding-right', '3px')
            $editButtonContainer.append $gridButton

        $editButtonContainer
        .append $printButton
        .appendTo @$container.find '.edit-buttons'

        if @options.excel.available
            $excelButton = $builtinButtonContainer.find '.buttons-excel'
            $excelButton
            .text ' ' + $excelButton.text()
            .prepend '<i class="fa fa-download">'
            $editButtonContainer.append $excelButton
        if @options.excel.defaultPlugin
            $excelButton = $builtinButtonContainer.find '.buttons-excel'
            $excelButton
            .text ' ' + $excelButton.text()
            .prepend '<i class="fa fa-download">'
            $editButtonContainer.append $excelButton

        @$container.find('.builtin-buttons .btn-group').addClass 'btn-group-justified'

    adjustDom: =>
        title = @$container.find('table').data 'title'
        @$container.find '.grid-header'
        .append $ "<div class='lead'>#{title}</div>"

        $search = @$container.find '.dataTables_filter'

        $search.find 'label input'
        .appendTo $search
        .removeClass 'input-sm'

        $inputGroup = $ '<div class="input-group">'
        .append $ '<span class="input-group-addon"><i class="fa fa-search"/></span>'
        .append $search.find 'input'
        .appendTo $search

        $search.find 'label'
        .addClass 'sr-only'

        @$container.find '.dataTables_info'
        .addClass 'pull-right'

    setupEvents: =>
        selectionChangedHandler = =>
            event = $.Event('change.selection')
            event.rows = @state.rows('.selected')
            @$container.find('table').trigger(event)

            for key, config of @editControls
                continue unless (key isnt 'add' and config.mode is 'Url' and config.url)
                url = @completeUrlFromSelection config.url
                config.$button.attr 'href', url

        for key, config of @editControls
            if config.mode isnt 'Popup'
                config.$button.off 'click'

        if @editControls.delete.mode is 'Inline'
            @editControls.delete.$button.on 'click', (event) =>
                event.preventDefault()
                @editor
                .remove('.selected', false)
                .submit()
                # @table.row('.selected').remove().draw()
                selectionChangedHandler()

        if @editControls.add.mode is 'Inline'
            @editControls.add.$button.on 'click', (event) =>
                newRow = {}
                (newRow[col.name] = cloneObject col.default) for col in @columns
                newRow.DT_RowId = newRow[@idColumn] or (@$table.attr('id') + '_new_row_' + @counter++)
                @table.row.add(newRow).draw()
                event.preventDefault()
                @$table.trigger('postCreate')

        if @editControls.edit.mode is 'Inline'
            @editControls.edit.$button
            .disable()
            .on 'click', (event) -> event.preventDefault()
            @table.on 'click', 'tbody td' + (if @options.selection then ':not(:first-child)' else ''), (event) =>
                if event.target.tagName is 'A'
                    return true
                @editor.inline event.currentTarget,
                    onBlur: 'submit'
                    #submit: 'allIfChanged'
                return false

        @table.on 'select', selectionChangedHandler
        @table.on 'deselect', selectionChangedHandler

        if @options.selection in ['multi', 'os']
            @$container.on 'click', '.buttons-select-all', =>
                @$table.trigger 'select-all'

        if @options.selection
            @$container.on 'click', '.buttons-select-none', =>
                @$table.trigger 'select-none'

        if @editControls.edit.mode isnt 'Inline'
            @table.keys.disable()
        else
            @editor
            .on 'open', ( e, mode, action ) => @table.keys.disable() if mode is 'main'
            .on 'close', => @table.keys.enable()

        @editor.on 'preOpen', (e) =>
            for fieldName in @editor.fields()
                field = @editor.field(fieldName)
                $(field.node())
                .find('.DTE_Field_InputControl')
                .addClass('form-group')
                .css('margin-left','0px')
                .css('margin-right','0px')
                .css('margin-right','-3px')
#                selectize = field.inst()
#                continue if selectize is undefined
#                selectize.addOption()
#                selectize.addItem()

        @editor.on 'setData', (e, json, data) =>
            for fieldName in @editor.fields()
                field = @editor.field(fieldName)
                continue if field.inst is undefined
                selectize = field.inst()
                continue if selectize.items is undefined
                id = selectize.items[0]
                continue if id is undefined
                name = selectize.options[id].label
                data[fieldName.split(".")[0]]['name'] = name

        @editor.on 'preSubmit', (e, data, action) =>
            greaterThanOne = (data) ->
                if data > 1
                    return {valid: true}
                else
                    return {valid: false, error: 'data is 1 or less'}

            if action is 'edit'
                for column, i in @columns
                    continue unless column.inputValidation

                    result = column.inputValidation data[i]

                    if not result.valid
                        @editor.modifier().node().classList.add 'has-error'
                        return false
            if action isnt 'remove'
                for column in @columns
                    continue unless column.visible and column.required

                    field = @editor.field column.name
                    continue if field is undefined

                    unless field.isMultiValue() or column.type is 'Boolean' or field.val()
                        field.error @$table.data('required-message').replace '{name}', column.title

                return not @editor.inError()

        @editor.on 'preOpen', (event, mode, action) =>
            if mode is 'inline'
                if action is 'edit'
                    $td = $ @editor.modifier()
                    index = @table.cell($td).index()
                    columnName = @table.column(index.column).dataSrc()
                    column = @columnLookup[columnName]

                    if column.readonly
                        $marker = $ '<div class="readonly-marker fade"><i class="fa fa-ban fa-lg readonly-marker"></div>'
                        .appendTo $td

                        setTimeout (-> $marker.addClass 'in'), 10

                        $marker
                        .on 'transitionend', ->
                            $marker
                            .off 'transitionend'
                            .removeClass 'in'
                            .on 'transitionend', -> $marker.remove()

                        return false

        applyColumnOptions = (event, node, data) =>
            for column in @columns
                continue unless column.visible
                colName = if not column.loadAction then column.name else "#{column.name}.uid"

                field = @editor.field colName

                if column.loadAction and data
                  field.inst().addOption
                    value: data[column.name].uid
                    label: data[column.name].name
                  field.inst().addItem data[column.name].uid

                if column.readonly
                    field.disable()
                    field.input().disable()

                if column.type is 'DateTime'
                    datepicker = field.input().parent().data('DateTimePicker')
                    datepicker.locale getCurrentLanguage()
                    datepicker.format(column.datetime.format)

        @editor.on 'initCreate', applyColumnOptions
        @editor.on 'initEdit', applyColumnOptions

    applyDefaults: (data) =>
        for column in @columns
            continue if data[column.name]

            data[column.name] = column.default
        return data

    inlineEditHandler: (event) =>

    bubbleEditHandler: (event) =>

    bindToSelection: ($element) =>
        if @table.row('.selected').length is 0
            $element.disable()
        @$table.on 'change.selection', (event) ->
            if event.rows.length is 0 then $element.disable() else $element.enable()

    completeUrlFromSelection: (url) =>
        selected = @table.rows('.selected')

        return null unless selected[0].length is 1

        return fillUrlPlaceholders url, @state.row('.selected')

    addAjaxSelection: => new AjaxSelection this

    cloneObject = (obj) ->
        if not obj? or typeof obj isnt 'object'
            return obj

        if obj instanceof Date
            return new Date(obj.getTime())

        if moment.isMoment(obj)
            return obj.clone()

        if obj instanceof RegExp
            flags = ''
            flags += 'g' if obj.global?
            flags += 'i' if obj.ignoreCase?
            flags += 'm' if obj.multiline?
            flags += 'y' if obj.sticky?
            return new RegExp(obj.source, flags)

        newInstance = new obj.constructor()

        for key of obj
            newInstance[key] = cloneObject obj[key]

        return newInstance

    add: (item) =>
        processedItem = @converter.processJSObject(item)
        @table.row.add(processedItem).draw()
        @state.addNewRow(processedItem)


createJqueryPlugin 'staticgrid', StaticGrid

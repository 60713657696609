class Validator
    constructor: (grid) ->
        @grid = grid
        @errors = {}

    addError: ({rowId, value, column, error}) =>
        console.log error
        previousValidationState = @valid()

        if not @errors[rowId]
            @errors[rowId] = {}
        @errors[rowId][column.name] = {column, value, error}

        currentCell = @grid.table.cell("##{rowId}", "#{column.name}:name").node()
        currentCell.classList.add 'has-error'
        currentCell.parentNode.classList.add 'has-error'

        if previousValidationState isnt @valid()
            args = {
                isValid: false
                control: @grid.$table
            }
            @grid.$table.trigger('change:validation', [args])

    clearError: ({rowId, column}) =>
        return unless @errors[rowId]

        previousValidationState = @valid()

        delete @errors[rowId][column.name]
        currentCell = @grid.table.cell("##{rowId}", "#{column.name}:name").node()
        if not @grid.state.hasAnyRowToDelete()
            currentCell.classList.remove 'has-error'

        if not Object.getOwnPropertyNames(@errors[rowId]).length
            delete @errors[rowId]
            if not @grid.state.hasAnyRowToDelete()
                currentCell.parentNode.classList.remove 'has-error'

        if previousValidationState isnt @valid()
            args = {
                isValid: true
                control: @grid.$table
            }
            @grid.$table.trigger('change:validation', [args])

    valid: => not Object.getOwnPropertyNames(@errors).length

    clearErrors: (args) =>
        rowId = args?.rowId
        for column in @grid.columns
            ids = if rowId then [rowId] else @grid.table.rows().ids()
            for rowId in ids
                @clearError({rowId, column})

    validateRow: (data) =>
        rowId = data.DT_RowId

        for column in @grid.columns
            value = data[column.name]
            continue unless column.dataValidation

            do (column) =>
                column.dataValidation value
                .then =>
                    @clearError({rowId, column})
                .catch (error) =>
                    @addError({rowId, value, column, error})

    validate: =>
        data = @grid.table.rows().data()
        for row in data
            @validateRow row

module.exports = {Validator}

module.exports.__esModule = true
module.exports.default = Validator


{TextFilterBehaviour} = require './text'
{NumberFilterBehaviour} = require './number'
{DateFilterBehaviour} = require './date'

class ColumnFilter
    constructor: (@column, useServerSide) ->
        @colType = @column.settings()[0]
            .aoColumns
            .filter((col) => col.data is @column.dataSrc())[0]
            .type
        @behaviour = switch @colType
            when 'string' then new TextFilterBehaviour()
            when 'num' then new NumberFilterBehaviour()
            when 'date' then new DateFilterBehaviour()
            else new TextFilterBehaviour()

        @filterBuilder = if useServerSide then @ajaxFilterBuilder else @clientFilterBuilder
        @render()

    render: ->
        headerContent = document.createElement 'span'
        #@column.header().appendChild document.createElement 'br'
        headerContent.appendChild child.cloneNode() for child in @column.header().childNodes

        @filterIcon = document.createElement 'span'
        @filterIcon.classList.add 'fa'
        @filterIcon.classList.add 'fa-caret-down'
        @filterIcon.classList.add 'fa-border'

        toggleControl = document.createElement 'span'
        toggleControl.classList.add 'dropdown-toggle'
        toggleControl.setAttribute 'data-toggle', 'dropdown'
        toggleControl.appendChild @filterIcon
        toggleControl.addEventListener 'click', (e) -> e.stopPropagation()
        toggleControl.style.marginLeft = '4px'

        search = @behaviour.template() +
            '''<button type="button" class="btn btn-success"><span class="fa fa-filter"></span></button>
               <button type="button" class="btn btn-danger"><span class="fa fa-times"></span></button>'''

        form = document.createElement 'div'
        form.classList.add 'form-inline'
        form.innerHTML = search

        @behaviour.attachTo form

        columnDiv = document.createElement 'div'
        columnDiv.classList.add 'col-xs-12'
        columnDiv.appendChild form

        menuControl = document.createElement 'div'
        menuControl.classList.add 'dropdown-menu'
        menuControl.appendChild columnDiv
        menuControl.addEventListener 'click', (e) -> e.stopPropagation()
        document.body.appendChild menuControl

        dropDown = document.createElement 'div'
        dropDown.classList.add 'dropdown'
        dropDown.appendChild headerContent
        dropDown.appendChild toggleControl

        $(dropDown).on 'show.bs.dropdown', ->
            top = $(dropDown).parent().offset().top + $(dropDown).parent().innerHeight()
            left = $(dropDown).parent().offset().left
            $(menuControl)
            .css 'display', 'block'
            .css 'top', top
            .css 'left', left

        $(dropDown).on 'shown.bs.dropdown', =>
            @behaviour.focus()

        $(dropDown).on 'hidden.bs.dropdown', (e) =>
            $(menuControl)
            .css 'display', ''
            .css 'display', 'hidden'
            @executeSearch()

        $(form).on 'keyup', (event) =>
            if event.which is 13 or event.which is 27 then $(dropDown).dropdown('toggle')
            if event.which is 13 then @executeSearch()
            event.stopPropagation()

        $(form).find('button.btn-success').on 'click', =>
            $(dropDown).dropdown('toggle')
            @executeSearch()

        $(form).find('button.btn-danger').on 'click', =>
            $(dropDown).dropdown('toggle')
            @resetFilterIndicator()
            @behaviour.clear()
            @executeSearch()

        @column.header().removeChild @column.header().firstChild while @column.header().firstChild

        @column.header().appendChild dropDown

        $(toggleControl).dropdown()

    clientFilterBuilder: (operation, operand, operand2) ->
        searchValue = switch operation
            when 'startswith' then "^#{operand}"
            when 'endswith' then "#{operand}$"
            when 'is' then "^#{operand}$"
            when 'contains' then ".*?#{operand}.*?"
        return searchValue

    ajaxFilterBuilder: (operation, operand, operand2) ->
        return "#{operation}{SEP}#{operand}" + (if operand2 then "{SEP}#{operand2}" else "")

    executeSearch: ->
        if @behaviour.isFilterSet()
            @setFilterIndicator()
            searchValue = @behaviour.getFilterString(@filterBuilder)
        else
            @resetFilterIndicator()
            searchValue = ''
        @column.search(searchValue, true, true).draw()

    setFilterIndicator: ->
        @filterIcon.classList.remove 'fa-caret-down'
        @filterIcon.classList.add 'fa-filter'

    resetFilterIndicator: ->
        @filterIcon.classList.remove 'fa-filter'
        @filterIcon.classList.add 'fa-caret-down'

module.exports = {ColumnFilter}

module.exports.__esModule = true
module.exports.default = ColumnFilter

const createJqueryPlugin = (pluginName, PluginClass) => {
  $.fn[pluginName] = function (options) {
    const instances = []

    for (const element of this.toArray()) {
      const dataKey = `plugin_${pluginName}`
      const $element = $(element)
      // create new plugin instance if the element does not already have an instance attached
      const pluginInstance = $element.data(dataKey) || new PluginClass($element, options)
      instances.push(pluginInstance)
      // store plugin instance reference in host DOM element
      $element.data(dataKey, pluginInstance)
    }

    return instances
  }
}

export {
  createJqueryPlugin
}

export default createJqueryPlugin

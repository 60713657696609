require('datatables.net')
require('datatables.net-bs')
require('datatables.net-select')
require('datatables.net-buttons')
require('datatables.net-buttons-bs')
require('datatables.net-buttons/js/buttons.html5.js')
require('datatables.net-buttons/js/buttons.print.js')
require('datatables.net-keytable')
require('legacy-scripts/vendor/dataTables/jquery.dataTables.columnfiltering.js')

require('datatables.net-editor')
require('legacy-scripts/vendor/dataTables/editor.bootstrap.js')
require('legacy-scripts/vendor/dataTables/editor.selectize.js')
require('legacy-scripts/vendor/dataTables/editor.datetimepicker.js')

require('./field-types/bool-switch')
require('./field-types/datetime')
require('./field-types/num')

class TextFilterBehaviour
    template: ->
        '''
        <div class="form-group">
            <select class="form-control">
                <option selected="selected" value="contains">Enthält</option>
                <option value="startswith">Beginnt mit</option>
                <option value="endswith">Endet mit</option>
                <option value="is">Ist gleich</option>
            </select>
        </div>
        <div class="form-group">
            <input class="form-control" type="text" name="search" placeholder="Suchbegriff">
        </div>
        '''

    attachTo: (form) =>
        @form = form

    isFilterSet: =>
        return not not $(@form).find('input').val()

    getFilterString: (filterBuilder) =>
        return filterBuilder $(@form).find('select').val(), $(@form).find('input').val()
        # return "#{$(@form).find('select').val()}{SEP}#{$(@form).find('input').val()}"

    focus: =>
        $(@form).find("input[name='search']").focus()

    clear: =>
        $(@form).find('input').val('')

module.exports = {TextFilterBehaviour}

module.exports.__esModule = true
module.exports.default = TextFilterBehaviour

# CoffeeScript

class AjaxSelection
    constructor: (@grid) ->
        @$infoContainer = @grid.$container.find('.dataTables_info')
        @selection = []

        @grid.table.on 'select.dt.DT', @onSelect

        @grid.table.on 'deselect.dt.DT', @onDeselect

        @grid.$table.on 'select-none', => @selection = []

        @grid.table.on 'preXhr.dt.dtSelect', @beforeAjaxUpdate

        @grid.table.on 'draw.dtSelect.dt select.dtSelect.dt deselect.dtSelect.dt', =>
            setTimeout @updateSelectionInfo, 0

    rowsToIds: (rows) =>
        data = rows.data()

        if data.toArray
            data = data.toArray()
        else
            data = [data]

        return data.map((x) => x[@grid.idColumn])

    onSelect: (event, api, selectionMode, rows) =>
        selected = @rowsToIds(api).filter (x) => x not in @selection
        @selection = @selection.concat selected

    onDeselect: (event, api, selectionMode, rows) =>
        deselected = @rowsToIds(api)
        @selection = @selection.filter (x) -> x not in deselected

    beforeAjaxUpdate: =>
        @grid.table.one 'draw.dt.dtSelect', =>
            hashIds = @selection.map (x) -> '#' + x
            @grid.table.rows(hashIds).select()

    updateSelectionInfo: =>
        key = 'select.rows'
        text =
            _: '%d rows selected'
            0: ''
            1: '1 row selected'

        $info = $('<span class="select-item"/>').append @grid.table.i18n(key, text, @selection.length)

        $existing = @$infoContainer.find '.select-info'

        if @selection.length
            if not $existing.length
                $existing = $('<span class="select-info"/>').appendTo @$infoContainer
            $existing.empty().append $info
            $existing.append $('<span class="select-info"/>')
            $existing.append $('<span class="select-info"/>')
        else
            $existing.remove()

module.exports.__esModule = true
module.exports.default = AjaxSelection

# CoffeeScript

{Api} = $.fn.dataTable
{getCurrentLocale} = require 'src/js/i18n'

class ValueConverter
    constructor: (@grid) ->

    applyToObjectColumns: (object, f) ->
        copy = $.extend true, {}, object
        for key, value of copy
            column = @grid.columnLookup[key]
            continue unless column
            copy[key] = f value, column
        return copy

    localize: (value, column) =>
        switch column.type
            when 'DateTime', 'Float'
                @display value, column
            else
                value

    localizeObject: (object) -> @applyToObjectColumns object, @localize

    display: (value, column) ->
        switch column.type
            when 'Boolean'
                if value is true
                    return '<i class="fa fa-check"></i>'
                if value is false
                    return '<i class="fa fa-close"></i>'
                return '<i class="fa fa-question"></i>'
            when 'DateTime'
                if value?
                    return value.locale(getCurrentLocale()).format(column.datetime.format)
            when 'Float'
                if value? and not isNaN(value)
                    return Globalize.numberFormatter(column.numberFormatter) value
            when 'Uri'
                if value?
                    if /.*:\/\/.+/.test(value)
                        return "<a href='#{value}' target='_blank'>#{value}</a>"
                    else
                        return value
            when 'LinkUri'
                if value?
                    return "<a href='#{value}' data-ajax='false' target='_blank'> <span class='glyphicon glyphicon-circle-arrow-right' aria-hidden='true'></span></a>"
            when 'String'
                if column.maxLength > 0 and value.length > column.maxLength
                    return "<span title='#{value}'>#{value.substr(0, column.maxLength)}&hellip;</span>"

        if column.type of @grid.enums
            for item in @grid.enums[column.type]
                if item.value is value
                    return item.label

        if column.name of @grid.guidSelects
            for item in @grid.guidSelects[column.name]
                if item.value is value
                    return item.label

        return value

    parse: (value, column) =>
        # bugfix/workaround multi edit undo, parser error for bool and float values
        if typeof(value) isnt 'string'
            return value

        switch column.type
            when 'Integer'
                if column.nullable and value is ''
                    return null
                return parseInt value
            when 'Float'
                if column.nullable and value is ''
                    return null
                return Globalize.numberParser() value

        if column.type of @grid.enums
            return value

        return value

    parseObject: (object) -> @applyToObjectColumns object, @parse

    processJS: (value, column) =>
        return null if value is null

        if column.type is 'DateTime'
            return moment(value)

        if column.type of @grid.enums
            for item in @grid.enums[column.type]
                if item.label is value or item.value is value
                    return item.value
            return null

        return value

    processObject: (object) => @applyToObjectColumns object, @process

    processJSObject: (object) =>
        processed = @applyToObjectColumns object, @processJS
        processed.DT_RowId = object[@grid.idColumn]
        return processed

    renderFunction: (data, type, row, meta) =>
        api = new Api(meta.settings)
        column = @grid.columnLookup[api.column(meta.col).dataSrc()]
        return data unless column
        return @display data, column

    sortDisplay: (value, column) ->
        switch column.type
            when 'Boolean'
                if value is true
                    return 2
                if value is false
                    return 1
                return 0
            when 'DateTime'
                if value?
                    return value.valueOf()
                else
                    return 0
            when 'Float'
                if value?
                    return Globalize.numberFormatter(column.numberFormatter) value

        if column.type of @grid.enums
            for item in @grid.enums[column.type]
                if item.value is value
                    return item.label

        if column.name of @grid.guidSelects
            for item in @grid.guidSelects[column.name]
                if item.value is value
                    return item.label

        return value

    sortFunction: (data, type, row, meta) =>
        api = new Api(meta.settings)
        column = @grid.columnLookup[api.column(meta.col).dataSrc()]
        return data unless column
        return @sortDisplay data, column

module.exports = {
    ValueConverter
}

module.exports.__esModule = true
module.exports.default = ValueConverter

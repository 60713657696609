# CoffeeScript

$.fn.dataTable.ext.editorFields or= {}

{editorFields} = $.fn.dataTable.ext
{Editor} = $.fn.dataTable

fieldDiv = (conf) -> $('<div>').attr('id', Editor.safeId(conf.id))

button = (icon, value) ->
    $icon = $ '<i>'
    .addClass 'fa fa-' + icon

    return $ '<button>'
    .addClass 'btn btn-default'
    .attr 'value', '' + value
    .append $icon

editorFields.boolSwitch =
    create: (conf) ->
        api = this

        conf._enabled = true

        $div = fieldDiv conf

        $group = $('<div>')
        .addClass('btn-group')
        .appendTo $div

        $group.append button 'check', true
        $group.append button 'close', false

        if conf.options.nullable
            $group.append button 'question', null

        $group.on 'click', 'button', (event) ->
            if conf._enabled
                api.set conf.name, $(this).attr('value')

        conf._input = $div
        return $div

    get: (conf) ->
        value = $('button.active', conf._input).attr('value')
        JSON.parse value

    set: (conf, val) ->
        activeClasses =
            true: 'btn-success'
            false: 'btn-danger'
            null: 'btn-info'

        $button = $('button.active', conf._input)
        $button.removeClass 'active'
        $button.removeClass activeClasses[$button.attr 'value']
        $button.addClass 'btn-default'
        $button = $("button[value=#{val}]", conf._input)
        $button.addClass 'active'
        $button.addClass activeClasses[val]

    enable: (conf) ->
        conf._enabled = true
        $('button', conf._input).enable()

    disable: (conf) ->
        conf._enabled = false
        $('button', conf._input).disable()

class NumberFilterBehaviour
    template: ->
        '''
        <div class="form-group">
            <select class="form-control">
                <option selected="selected" value="at">Gleich</option>
                <option value="between">Intervall</option>
                <option value="lessThan">Kleiner</option>
                <option value="greaterThan">Größer</option>
            </select>
        </div>
        <div class="form-group">
            <input class="form-control" type="number" name="from" placeholder="von">
        </div>
        <div class="form-group">
            <input class="form-control" type="number" name="to" placeholder="bis" disabled="disabled">
        </div>
        '''

    attachTo: (form) =>
        @form = form

        $(@form).find('select').on 'change', (event) =>
            $toInputBox = $(@form).find("input[name='to']")
            if $(@form).find('select').val() is 'between'
                $toInputBox.enable()
            else
                $toInputBox.val('')
                $toInputBox.disable()

    isFilterSet: =>
        operation = $(@form).find('select').val()
        fromValue = $(@form).find("input[name='from']").val()
        toValue = $(@form).find("input[name='to']").val()
        fromValue and (not (operation is 'between') or toValue)

    getFilterString: (filterBuilder) =>
        operation = $(@form).find('select').val()
        fromValue = $(@form).find("input[name='from']").val()
        toValue = $(@form).find("input[name='to']").val()
        filterBuilder operation, fromValue, toValue

    focus: =>
        $(@form).find("input[name='from']").focus()

    clear: =>
        $(@form).find('input').val('')

module.exports = {NumberFilterBehaviour}

module.exports.__esModule = true
module.exports.default = NumberFilterBehaviour

# CoffeeScript

class StateTracker
    constructor: (grid) ->
        @grid = grid
        @counter = 0
        @reset()

    addNewRow: (data) =>
        @_trackAddition data
        @grid.table.row('#' + data.DT_RowId).node().classList.add 'added'

    setupEvents: ->
        @grid.table.on 'draw.dt', (event, json, data) =>
            @grid.validator?.validate()


        @grid.editor.on 'postCreate', (event, json, data) =>
            @_trackAddition data


        @grid.editor.on 'postEdit', (event, json, data) =>
            @_trackEditing data

            modifier = @grid.editor.modifier()
            if modifier instanceof HTMLElement and modifier.tagName is 'TD'
                modifier.parentNode.classList.add 'modified'
            else
                @grid.table.row(@grid.editor.modifier()).node().classList.add 'modified'



        @grid.editor.on 'postRemove', =>
            for object in @_removeBuffer
                @_trackRemoving object
            @_removeBuffer = []

        @grid.editor.on 'initEdit', (event, node, data) =>
            localized = @grid.converter.localizeObject data
            for key, value of localized
                field = @grid.editor.field key
                if field and not field.isMultiValue()
                    @grid.editor.set key, value

    reset: =>
        @_added = []
        @_modified = []
        @_deleted = []
        @_removeBuffer = []

        @grid.table?.rows('.added,.modified').nodes().to$().removeClass('added modified')

    _toData: (id) =>
        @grid.table.row("##{id}").data()

    _stripId: (data) ->
        clone = $.extend {}, data
        delete clone.DT_RowId
        return clone

    _convertTypes: (data) ->
        for key, value of data
            if moment.isMoment value
                data[key] = value.toISOString()
        return data

    _trackAddition: (data) =>
        rowId = data.DT_RowId

        @grid.validator?.validateRow(data)

        @_added.push rowId

    addRow: (data, idColumn = null) =>
        if idColumn
            data.DT_RowId = data[idColumn]
        if not data.DT_RowId
            data.DT_RowId = @grid.$table.attr('id') + '_new_row_' + @counter++
        @_trackAddition data
        @grid.table.row.add(data).draw()

    _trackEditing: (data) =>
        rowId = data.DT_RowId
        @grid.validator?.validateRow(data)

        unless rowId in @_added
            unless rowId in @_modified
                @_modified.push rowId

    editRow: (data) =>
        @_trackEditing data
        row = @grid.table.row("##{data.DT_RowId}")
        row.data data

    _trackRemoving: (data) =>
        rowId = data.DT_RowId

        @grid.validator?.clearErrors {rowId}

        @_modified = @_modified.filter (x) -> x isnt rowId
        if rowId in @_added
            @_added = @_added.filter (x) -> x isnt rowId
            return

        #row = @grid.table.row("##{rowId}")
        @_deleted.push data

    _removeRow: (data) =>
        @_trackRemoving data
        @grid.table.row("##{data.DT_RowId}").remove()

    rows: (rowSelector) => @grid.table.rows(rowSelector).data().toArray().map(@_stripId).map(@_convertTypes)

    row: (rowSelector) => @_convertTypes @_stripId @grid.table.row(rowSelector).data()

    changes: =>
        added: @_added.map(@_toData).map(@_stripId).map(@_convertTypes)
        updated: @_modified.map(@_toData).map(@_stripId).map(@_convertTypes)
        deleted: @_deleted.map(@_stripId).map(@_convertTypes)

    hasChanges: =>
        return @_added.length > 0 or @_modified.length > 0 or @_deleted.length > 0

    hasAnyRowToDelete: -> @_removeBuffer.length > 0


    ajax: (method, url, d, successCallback, errorCallback) =>
        output =
            data: []

        switch d.action
            when 'create'
                # console.log 'added rows'
                for key, object of d.data
                    object.DT_RowId = @grid.$table.attr('id') + '_new_row_' + @counter++

                    parsed = @grid.converter.parseObject object

                    @grid.applyDefaults parsed

                    output.data.push parsed
            when 'edit'
                # console.log 'edited rows'
                for key, object of d.data
                    row = @grid.table.row("##{key}")

                    parsed = @grid.converter.parseObject object

                    output.data.push $.extend row.data(), parsed
            when 'remove'
                # console.log 'deleted rows'
                for key, object of d.data
                    @_removeBuffer.push object

        successCallback output


module.exports = {
    StateTracker
}

module.exports.__esModule = true
module.exports.default = StateTracker
